import {Injectable} from "@angular/core";
import {FooterComponent} from "./footer.component";

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  public targetComponent: FooterComponent = null;

  public registerTargetComponent(targetComponent) {
    this.targetComponent = targetComponent;
  }

  public setShowFooter(showFooter: boolean) {
    if(showFooter != null) {
      this.targetComponent.showFooter = showFooter;
    }
  }


}
